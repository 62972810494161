export const cs = {
  language: 'Czech',
  languageId: 'cs',
  locale: 'cs-CZ',
  navigationBar: {
    createWarning: 'Map',
    clients: 'Clients',
    partners: 'Partners',
    warningList: 'Warning History',
    statistics: 'Statistics',
    invoicing: 'Invoicing',
    logout: 'Logout',
    users: 'Users',
    templates: 'Templates',
  },
  common: {
    uidError: 'UID must contain only numbers',
    zipError: 'Valid ZIP code required',
    emailError: 'Valid email address required',
    phoneError: 'Valid phone number required',
    requiredError: 'This field is required',
    notRequired: 'This field is optional',
    elevationError: 'Elevation must be a number',
    templateEmpty: 'Select warning type',
    value: 'Value',
    cancel: 'Cancel',
    cancelWarning: 'Cancel Warning',
    confirm: 'Confirm',
    givenName: 'Given Name',
    surname: 'Surname',
    email: 'Email',
    phoneNumber: 'Phone Number',
    delete: 'Delete',
    deletedAt: 'Deleted At',
    noResults: 'No results',
    resetFilters: 'Reset',
    filter: 'Filter',
    filters: 'Filters',
    true: 'Yes',
    false: 'No',
    uid: 'UID',
    id: 'ID',
    czechia: 'Czech Republic',
    slovakia: 'Slovakia',
    send: 'Send',
    sendWarning: 'Send Warning',
    save: 'Save',
    loadMore: 'Load More',
    error: 'An error occurred',
    close: 'Close',
    createdAt: 'Created At',
    role: 'Role',
    edit: 'Edit',
    saveSuccess: 'Successfully saved',
  },
  integrations: {
    active: 'Active',
    type: 'Type',
    country: 'Country',
    value: 'Value',
    test: 'Test',
    integrations: 'Integrations',
    yes: 'Yes',
    no: 'No',
    templates: 'Templates',
    partnerId: 'Partner ID',
  },
  createWarning: {
    basicMap: 'Basic',
    geologicalMap: 'Geological',
    czSkMap: 'CZ/SK',
    country: 'Country',
    fromElevation: 'From Elevation',
    template: 'Warning Type',
    warningType: 'Warning Type',
    notNotifyReported:
      'Do not send to clients who were notified in previous report.',
    testTitle: 'Test Warning',
    testSubtitle: 'Send only to test clients',
    chooseDate: 'Choose Date',
    dateFrom: 'Date From',
    dateTo: 'Date To',
    dateFromError: 'Date FROM must be in the future',
    dateToError: 'Date TO must be after date FROM',
    draw: 'Edit Warning',
    erase: 'Erase Warning',
    range: 'Pen Size',
    eraseAll: 'Erase All',
    stop: 'Stop Editing',
    move: 'Move on Map',
    lock: 'Lock Map',
    unlock: 'Unlock Map',
    noMapDataTitle: 'No selected area',
    noMapData: 'To send warning, some area must be selected on map.',
    close: 'Close',
    removeLayer: 'Remove Layer',
    wind: 'Wind',
    temperature: 'Temperature',
    precipitation: 'Precipitation',
    snow: 'Snow',
    opacity: 'Opacity',
    layers: 'Layers',
    mask: 'Mask',
    nextImage: 'Next Image',
    previousImage: 'Previous Image',
    toggleRadar: 'Toggle Radar',
    toggleMask: 'Toggle Mask',
    rough: 'Rough',
    prediction: 'Prediction',
    detailed: 'Detailed',
    undo: 'Undo',
    redo: 'Redo',
    mapMenu: 'Map',
    noLayer: 'No Layer',
    brushSize: 'Brush Size',
    goThroughLayer: 'Go Through Layer',
    radar: 'Radar',
    confirmWarning: 'I want to send',
    warningLayers: 'Warnings',
    toggleWarningLayers: 'Toggle Current Warnings',
    from: 'From',
    to: 'To',
    warningSent: 'Warning Sent',
    warningLink: 'You can view the detail of the sent warning here:',
    warningDetail: 'Warning Detail',
    warningDurationTooLong: 'Warning duration is longer than 72 hours',
    warningStartsTooLate: 'Warning starts more than 48 hours later',
    warningWrongDate: 'Date is in wrong format',
    warningShort: 'Minimum warning length is 10 minutes',
    fieldRequired: 'This field is required',
    warningError: 'An error occurred while creating warning',
    error: 'Problem occurred',
    warningTestClients: 'Warning will be sent only to test clients',
    warningTestEnv: 'Warning will be sent to clients in test environment',
    warningSimulation:
      'This warning is a simulation and will not be sent to anyone',
    warningRealClients: 'Warning will be sent to real clients',
    actualTime: 'Currently',
    testLocality: 'Test Locality',
  },
  warningDetail: {
    total: 'Total',
    sent: 'Sent',
    failed: 'Failed',
    sms: 'SMS',
    fcm: 'FCM',
    integrations: 'Integrations',
    mails: 'Emails',
    author: 'Author',
    template: 'Template',
    timeFrom: 'Time From',
    timeTo: 'Time To',
    createdAt: 'Created At',
    sendingAt: 'Start Sending',
    updatedAt: 'Last Message Time',
    clientCount: 'Clients',
    zipCount: 'ZIP Codes',
    values: 'Values',
    status: 'Warning Status',
    test: 'Test Warning',
    yes: 'Yes',
    fromElevation: 'From Elevation',
    mnm: 'm above sea level',
  },
  landing: {
    swaps: 'SWAPS',
    meteopress: '--METEOPRESS--',
    login: 'Login',
  },
  clientList: {
    partner: 'Partner',
    test: 'Test',
    createClient: 'Insert Client',
    createdAt: 'Created At',
    deletedAt: 'Deleted At',
    unsubscribedAt: 'Unsubscribed At',
    includeDeleted: 'Show Deleted',
  },
  clientDetail: {
    unsubscribeTitle: 'Unsubscribe from Service',
    unsubscribeSubtitle:
      'Do not send warnings based on client request to unsubscribe from the service',
    contactsTitle: 'Contacts',
    client: 'Client',
    emailTitle: 'Email Addresses',
    addEmail: 'Add Email Address',
    email: 'Address',
    phoneNumbersTitle: 'Phone Numbers',
    addPhone: 'Add Phone Number',
    number: 'Number',
    locationsTitle: 'Locations',
    actualLocationsTitle: 'Actual Locations',
    addLocation: 'Add Location',
    saveSuccessTitle: 'Successfully Saved',
    saveSuccessDescription: 'Client was successfully saved',
    zips: 'Area',
    locations: 'locations',
    location: {
      country: 'Country',
      language: 'Language',
      zip: 'ZIP Code',
      lat: 'Latitude',
      lng: 'Longitude',
      radius: 'Radius',
      elevation: 'Elevation',
      mnm: 'm above sea level',
    },
    note: 'Note',
    delete: 'Delete Client',
    unsubscribe: 'Unsubscribe Client',
  },
  partnerList: {
    note: 'Note',
    sendWarnings: 'Send Warnings',
    createPartner: 'Insert Partner',
  },
  partnerDetail: {
    partner: 'Partner',
    saveSuccessTitle: 'Successfully Saved',
    saveSuccessDescription: 'Partner was successfully saved',
    delete: 'Delete Partner',
  },
  warningList: {
    createdAt: 'Created At',
    country: 'Country',
    type: 'Template',
    value: 'Value',
    userName: 'Author',
    status: 'Status',
    detail: 'Detail',
    filter: 'Filter',
    test: 'Test',
    yes: 'Yes',
  },
  templates: {
    subject: 'Subject',
    type: 'Template Type',
    warningType: 'Warning Type',
    id: 'ID',
    partner: 'Partner',
    templateDetail: 'Template Detail',
    template: 'Template',
    iframePreview: 'iFrame Preview',
    previewHtml: 'HTML Preview',
    patchSuccess: 'Template successfully saved',
    createTemplate: 'Create Template',
    noValues: 'none',
    values: 'Values',
    delete: 'Delete',
    deleteConfirmation: 'Are you sure you want to delete the template',
    deleteSuccess: 'Template successfully deleted',
    createSuccess: 'Template successfully created',
  },
  statistics: {
    partners: 'Clients',
    clients: 'Clients',
    warning: 'Warnings',
    created: 'Created',
    notifications: 'Notifications',
    day: 'Day',
    clientCount: 'Client Count',
    createdCount: 'Created Count',
    notificationCount: 'Notification Count',
  },
  invoicing: {
    partner: 'Partner',
    nothingToDisplay: 'Nothing to display',
    monthStart: 'Date',
    warningsCount: 'Warnings Issued',
    clientsCountStart: 'Clients at the beginning of the month',
    clientsCountEnd: 'Clients at the end of the month',
    clientsAdded: 'Clients added',
    clientsDeleted: 'Clients deleted',
    warningType: 'Warning Type',
    warningDate: 'Warning Date',
    country: 'Country',
    smsCount: 'SMS Sent',
    emailsCount: 'Emails Sent',
    clients: 'Contacted Clients',
  },
  platform: {},
  modals: {
    clientInsertion: {
      title: 'Insert Client',
      partner: 'Select Partner',
      email: 'Email',
      emailTitle: 'Add Email Address',
      phone: 'Phone Number',
      phoneTitle: 'Add Phone Number',
      locaton: 'Location',
      locationTitle: 'Add Location',
      yes: 'Yes',
      no: 'No',
      testClient: 'Test Client',
      disableLastNotifiedCheck: 'Force send overlapping warnings',
      test: 'Test Client',
    },
    doubleCheckOrDie: {
      title: 'Send Warning',
      template: 'Warning Type',
      messageText: 'Message Text',
      from: 'Valid from',
      to: 'Valid to',
      fromElevation: 'From Elevation',
      notifiedZip: 'Warning will affect',
      mapTitle: 'Selected area',
      zipCount: (count: number) => `${count} ZIP Codes`,
      notifiedClients: 'Warning will affect',
      clientsCount: (count: number) => {
        switch (count) {
          case 1:
            return `${count} client`;
          case 2:
          case 3:
          case 4:
            return `${count} clients`;
          default:
            return `${count} clients`;
        }
      },
      warningDurationTooLong: 'Warning duration is longer than 72 hours',
      warningStartsTooLate: 'Warning starts more than 48 hours later',
      warningStartsIn: 'Warning starts in',
      warningDuration: 'Warning will be valid for',
      warningInPastWarn: 'Warning must be in the future.',
    },
    partnerInsertion: {
      title: 'Insert Partner',
      name: 'Name',
      note: 'Note',
      smsNo: 'SMS ID',
      emailFrom: 'Email Sender Address',
      emailFromNamePlaceholder: 'Replaces partner name',
      emailFromName: 'Email Sender Name',
    },
    deletionConfirm: {
      message: (type: string, value: string) =>
        `Do you really want to remove ${type} ${value}?`,
      type: {
        email: 'email address',
        phone: 'phone number',
        location: 'location',
        client: 'client',
        partner: 'partner',
      },
    },
    unsubscribeConfirm: {
      message: 'Are you sure you want to unsubscribe the client from warnings?',
    },
  },
  unauthorized: {
    message: 'You do not have permission to work with the system.',
  },
};
