import {
  V2Client,
  V2Contact,
  V2CreateContactRequest,
  V2CreateLocationRequest,
  V2Location,
  V2PatchClientRequest,
} from '../../../api/models';
import { createAction, props } from '@ngrx/store';
import { ClientsFilters } from '../../models/clients-filters.model';

export const getClientsRequestAction = createAction(
  'GetClientsRequestAction',
  props<{ filter: ClientsFilters }>(),
);
export const getClientsSuccessAction = createAction(
  'GetClientsSuccessAction',
  props<{ clients: V2Client[]; clearPreviousResults: boolean }>(),
);
export const getClientsFailureAction = createAction(
  'GetClientsFailureAction',
  props<{ error: Error }>(),
);

export const deleteClientRequestAction = createAction(
  'DeleteClientRequestAction',
  props<{ id: string }>(),
);
export const deleteClientSuccessAction = createAction(
  'DeleteClientSuccessAction',
  props<{ id: string }>(),
);
export const deleteClientFailureAction = createAction(
  'DeleteClientFailureAction',
  props<{ error: Error }>(),
);

export const unsubscribeClientRequestAction = createAction(
  'UnsubscribeClientRequestAction',
  props<{ id: string }>(),
);
export const unsubscribeClientSuccessAction = createAction(
  'UnsubscribeClientSuccessAction',
  props<{ client: V2Client }>(),
);
export const unsubscribeClientFailureAction = createAction(
  'UnsubscribeClientFailureAction',
  props<{ error: Error }>(),
);

export const createClientRequestAction = createAction(
  'CreateClientRequestAction',
  props<{ client: V2Client; filter: ClientsFilters }>(),
);
export const createClientSuccessAction = createAction(
  'CreateClientSuccessAction',
  props<{ client: V2Client; filter: ClientsFilters }>(),
);
export const createClientFailureAction = createAction(
  'CreateClientFailureAction',
  props<{ error: Error }>(),
);

export const getClientDetailsRequestAction = createAction(
  'GetClientDetailsRequestAction',
  props<{ id: string }>(),
);
export const getClientDetailsSuccessAction = createAction(
  'GetClientDetailsSuccessAction',
  props<{ client: V2Client }>(),
);
export const getClientDetailsFailureAction = createAction(
  'GetClientDetailsFailureAction',
  props<{ error: Error }>(),
);

export const patchClientRequestAction = createAction(
  'PatchClientRequestAction',
  props<{ client: V2PatchClientRequest }>(),
);
export const patchClientSuccessAction = createAction(
  'PatchClientSuccessAction',
  props<{ client: V2Client }>(),
);
export const patchClientFailureAction = createAction(
  'PatchClientFailureAction',
  props<{ error: Error }>(),
);

export const addClientContactRequestAction = createAction(
  'AddClientContactRequestAction',
  props<{ contact: V2CreateContactRequest }>(),
);
export const addClientContactSuccessAction = createAction(
  'AddClientContactSuccessAction',
  props<{ contact: V2Contact }>(),
);
export const addClientContactFailureAction = createAction(
  'AddClientContactFailureAction',
  props<{ error: Error }>(),
);

export const deleteClientContactRequestAction = createAction(
  'DeleteClientContactRequestAction',
  props<{ clientId: string; contactId: string }>(),
);
export const deleteClientContactSuccessAction = createAction(
  'DeleteClientContactSuccessAction',
  props<{ clientId: string }>(),
);
export const deleteClientContactFailureAction = createAction(
  'DeleteClientContactFailureAction',
  props<{ error: Error }>(),
);

export const addClientLocationRequestAction = createAction(
  'AddClientLocationRequestAction',
  props<{ location: V2CreateLocationRequest }>(),
);
export const addClientLocationSuccessAction = createAction(
  'AddClientLocationSuccessAction',
  props<{ location: V2Location }>(),
);
export const addClientLocationFailureAction = createAction(
  'AddClientLocationFailureAction',
  props<{ error: Error }>(),
);

export const deleteClientLocationRequestAction = createAction(
  'DeleteClientLocationRequestAction',
  props<{ clientId: string; locationId: string }>(),
);
export const deleteClientLocationSuccessAction = createAction(
  'DeleteClientLocationSuccessAction',
  props<{ clientId: string }>(),
);
export const deleteClientLocationFailureAction = createAction(
  'DeleteClientLocationFailureAction',
  props<{ error: Error }>(),
);
